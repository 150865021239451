var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",staticStyle:{"text-align":"left"}},[_c('div',{staticClass:"yt-breadcrumb-wrapper"},[_c('el-breadcrumb',{staticClass:"yt-breadcrumb"},[_c('el-breadcrumb-item',[_vm._v("考试管理")]),_c('el-breadcrumb-item',{attrs:{"to":"/manage/exam/post"}},[_vm._v("岗位测试")]),_c('el-breadcrumb-item',[_vm._v("参试详情")])],1)],1),_c('div',{staticClass:"table-container"},[_c('Table',{attrs:{"height":_vm.th,"stripe":"","columns":_vm.columns,"data":_vm.tableData,"loading":_vm.loading},scopedSlots:_vm._u([{key:"duration",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.timeStamp(row.duration)))])]}},{key:"status",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.status(row.status)))])]}},{key:"startTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.startTime, 'yyyy-MM-dd hh:mm')))])]}},{key:"endTime",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.$formatTime(row.endTime, 'yyyy-MM-dd hh:mm')))])]}}])}),_c('div',{staticClass:"page"},[_c('Page',{staticClass:"yt-page",class:'yt-page-' + (_vm.total.toString().length > 1 ? _vm.total.toString().length : 2),attrs:{"show-total":"","show-sizer":"","show-elevator":"","page-size-opts":[10, 20, 40, 100],"total":_vm.total,"current":_vm.page + 1},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.changePageSize}})],1)],1),_c('Modal',{attrs:{"title":"评测结果"},model:{value:(_vm.resModal),callback:function ($$v) {_vm.resModal=$$v},expression:"resModal"}},[_vm._l((_vm.result),function(item,index){return _c('div',{key:index,staticStyle:{"margin-top":"5px"}},[_c('span',{staticStyle:{"font-size":"1.2em","font-weight":"bold"}},[_vm._v(_vm._s(item.sectionName)+": "+_vm._s(item.score)+"分")]),_c('br'),_c('span',{staticStyle:{"font-size":"1.1em"},domProps:{"innerHTML":_vm._s(item.result === null ? '' : item.result.replace(/(\r\n)|(\n)/g, '<br>'))}})])}),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.resModal = false}}},[_vm._v("确认")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }