<template>
  <div class="content" style="text-align: left">
    <div class="yt-breadcrumb-wrapper">
      <el-breadcrumb class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item to="/manage/exam/post">岗位测试</el-breadcrumb-item>
        <el-breadcrumb-item>参试详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="table-container">
      <Table :height="th" stripe :columns="columns" :data="tableData" :loading="loading">
        <template slot-scope="{ row, index }" slot="duration">
          <span>{{ timeStamp(row.duration) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="status">
          <span>{{ status(row.status) }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="startTime">
          <span>{{ $formatTime(row.startTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="endTime">
          <span>{{ $formatTime(row.endTime, 'yyyy-MM-dd hh:mm') }}</span>
        </template>
      </Table>
      <div class="page">
        <Page
          class="yt-page"
          :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
          show-total
          show-sizer
          show-elevator
          :page-size-opts="[10, 20, 40, 100]"
          :total="total"
          :current="page + 1"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>
    </div>
    <Modal v-model="resModal" title="评测结果">
      <div v-for="(item, index) in result" :key="index" style="margin-top: 5px">
        <span style="font-size: 1.2em;font-weight: bold">{{ item.sectionName }}: {{ item.score }}分</span><br />
        <span style="font-size: 1.1em" v-html="item.result === null ? '' : item.result.replace(/(\r\n)|(\n)/g, '<br>')" />
      </div>
      <div slot="footer">
        <Button type="primary" @click="resModal = false">确认</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import postExam from '@api/postExam'
export default {
  name: 'PostResult',
  data() {
    return {
      th: document.body.clientHeight - 130,
      resModal: false,
      result: [],
      total: 0,
      loading: false,
      page: 0,
      size: 10,
      isSpread: false,
      tableData: [],
      columns: [
        {
          title: '姓名',
          key: 'userName',
          className: 'name-column'
        },
        {
          title: '邮箱',
          key: 'email',
          className: 'name-column'
        },
        {
          title: '得分',
          key: 'userScore',
          className: 'name-column'
        },
        {
          title: '用时',
          slot: 'duration',
          className: 'name-column'
        },
        {
          title: '状态',
          slot: 'status',
          className: 'name-column'
        },
        {
          title: '开始时间',
          slot: 'startTime',
          className: 'time-column'
        },
        {
          title: '结束时间',
          slot: 'endTime',
          className: 'time-column'
        }
      ],
      search: {
        answerPaperStatus: '',
        candidateName: '',
        examName: ''
      },
      isSearch: false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    // toDetail(row) {
    //   if (row.type === 1) {
    //     this.$router.push({
    //       path: '/candidate/resultDetail',
    //       query: {
    //         result: true,
    //         name: row.candidateName,
    //         examName: row.examName,
    //         id: row.answerPaperId,
    //         score: row.score
    //       }
    //     })
    //   } else {
    //     this.result = []
    //     exam.getEvaRes(row.answerPaperId).then(res => {
    //       for (let i in res.res.evaluationResults) {
    //         this.result.push(res.res.evaluationResults[i])
    //       }
    //       this.resModal = true
    //     })
    //   }
    // },
    reset() {
      this.page = 0
      this.size = 0
      this.search = {
        answerPaperStatus: '',
        candidateName: '',
        examName: ''
      }
      this.isSpread = false
      this.isSearch = false
      this.initData()
    },
    advSearch() {
      this.isSearch = true
      this.page = 0
      this.size = 10
      this.searchData(0, 10)
    },
    // searchData(page, size) {
    //   this.loading = true
    //   this.isSpread = false
    //   exam.getResult(page, size, this.search).then(res => {
    //     this.tableData = res.res.data
    //     this.total = res.res.total
    //     this.loading = false
    //   })
    // },
    timeStamp(second_time) {
      second_time = parseInt(second_time) * 60
      let time = parseInt(second_time) + '秒'
      if (parseInt(second_time) > 60) {
        let second = parseInt(second_time) % 60
        let min = parseInt(second_time / 60)
        time = min + '分' + second + '秒'

        if (min > 60) {
          min = parseInt(second_time / 60) % 60
          let hour = parseInt(parseInt(second_time / 60) / 60)
          time = hour + '小时' + min + '分' + second + '秒'

          if (hour > 24) {
            hour = parseInt(parseInt(second_time / 60) / 60) % 24
            let day = parseInt(parseInt(parseInt(second_time / 60) / 60) / 24)
            time = day + '天' + hour + '小时' + min + '分' + second + '秒'
          }
        }
      }
      return time === 'NaN秒' ? '' : time
    },
    status(status) {
      switch (status) {
        case 1:
          return '未开始'
        case 2:
          return '做题中'
        case 3:
          return '已暂停'
        case 4:
          return '已交卷'
        case 5:
          return '待批阅'
        case 6:
          return '已批阅'
        case 7:
          return '已退出'
        case 8:
          return '已超时'
      }
    },
    initData() {
      this.loading = true
      let params = this.$handleParams('postExamData')
      postExam
        .getAttendance(params.id, this.page, this.size)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    changePage(val) {
      this.page = val - 1
      this.initData()
    },
    changePageSize(val) {
      this.size = val
      this.initData()
    }
  }
}
</script>

<style scoped lang="less">
@import '../../../theme/variables';

.content {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  //display: flex;
  //flex-direction: column;
  //position: relative;
}

.func-bar {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  flex-grow: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding-left: 20px;

  .filter-btn {
    margin-right: 20px;
    min-width: 100px;
  }

  .search-container {
    display: flex;
    align-items: center;
    position: relative;

    .search ::v-deep .ivu-input {
      border: 0;
      border-bottom: 1px solid #ccc;
      border-radius: 0;

      &:focus {
        box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
      }
    }

    .advanced-container {
      flex: 1 0 auto;
      cursor: pointer;
      margin-left: 10px;
      font-size: 14px;
      padding-right: 20px;

      svg.icon {
        transition: all @default-transition;
        transform: rotate(0deg);
        margin-left: 8px;
      }

      svg.icon.spread {
        transform: rotate(180deg);
      }
    }

    .advanced-board {
      position: absolute;
      z-index: 1;
      top: 56px;
      right: 0;
      width: 320px;
      height: 0;
      overflow: hidden;
      border-right: solid 5px @primary-color;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      transition: all @default-transition;

      &.show {
        height: 250px;
      }

      .search {
        margin-right: 20px;
        height: 40px;
        border-radius: 4px;
      }

      .form {
        width: 300px;
        margin-top: 20px;
      }
    }
  }
}

.table-container {
  padding: 10px 20px;
  width: 100%;
  height: 100%;

  ::v-deep .flex-table {
    border-bottom: 1px solid #e8eaec;

    .status-container {
      display: flex;
      align-items: center;

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 4px;
      }
    }

    .operation-tooltip {
      margin-right: 10px;
      cursor: pointer;
      color: @primary-color;
      transition: all @default-transition;

      &:hover {
        color: @primary-hover-color;
      }
    }

    .name-column {
      width: 10%;
    }

    .time-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .department-column {
      width: 15%;
      flex: 1 1 auto;
    }

    .email-column {
      width: 15%;
    }

    .phone-column {
      width: 15%;
    }

    .operation-column {
      width: 15%;
    }
  }

  .page {
    flex: 0 0 auto;
    padding: 10px;
    font-size: 14px;

    .page-options {
      float: left;
      height: 32px;
      line-height: 32px;
    }
  }
}
::v-deep.flex-table.ivu-table-wrapper .ivu-table .ivu-table-body {
  overflow: hidden;
}
</style>
